"use client"

import { useParams } from "next/navigation"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { env } from "~/env"
import { LazyMotion } from "framer-motion"
import { Provider as JotaiProvider } from "jotai"

import type { ShopifySFCountryCode } from "@unlikelystudio/commerce-connector"
import { AlertProvider } from "@unlikelystudio/react-abstract-components"
import {
  AnalyticsProvider,
  CartProvider,
  CustomerProvider,
  ShopifyAnalytics,
  StoreProvider,
} from "@unlikelystudio/react-ecommerce-hooks"
import { useFixScrollbarOverflow, useStableVh, useVh } from "@unlikelystudio/react-hooks"

import { getCountry, getLang } from "~/lib/i18n/utils/get-i18n"
import { getProcessedLocale } from "~/lib/i18n/utils/get-processed-locale"
import { getStorePublicCredentials } from "~/lib/shopify/client/public"
import { CUSTOMER_METAFIELDS, getProductMetafieldProccessedKeys } from "~/lib/shopify/constants"
import { VARIANT_FIRST } from "~/lib/shopify/constants/variant"
import useIsIOS from "~/hooks/useIsIOS"
import { CustomAlerts } from "~/components/ui/Alerts"
import { globalStore } from "~/providers/ClientAppProvider/utils/jotai-store"
import { EventsBufferHandler } from "~/providers/GTMTrackingProvider/EventsBufferHandler"
import { GoogleReCaptchaProvider } from "~/providers/RecaptchaProvider"
import { PanelManager } from "~/managers/PanelManager"
import { PopinManager } from "~/managers/PopinManager"

const motionAsyncFeatures = () =>
  import("~/providers/ClientAppProvider/utils/motion-features").then((res) => res.default)

type ClientGlobalProviderProps = { children: React.ReactNode }

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const recaptchaScriptProps = {
  async: true,
  defer: true,
  appendTo: "body",
} as const

export function ClientAppProvider({ children }: ClientGlobalProviderProps) {
  const params = useParams()

  // TODO: add to hasUserConsent props to be RGPD compliant some day
  // const cookiesConsentDone = useCookiesConsentDone()

  useIsIOS()
  useStableVh()
  useVh()
  useFixScrollbarOverflow()

  const shopifyCredential = getStorePublicCredentials()

  return (
    <GoogleReCaptchaProvider reCaptchaKey={env.NEXT_PUBLIC_RECAPTCHA_KEY} scriptProps={recaptchaScriptProps}>
      <StoreProvider
        currency={"EUR"}
        storeUrl={shopifyCredential?.storeUrl}
        storefrontAccessToken={shopifyCredential?.storefrontAccessToken}
        locale={getProcessedLocale(params.locale)}
        country={getCountry(params.locale).toUpperCase() as ShopifySFCountryCode}
        //@ts-ignore
        language={getLang(params.locale).toUpperCase()}
      >
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <CartProvider
            cartInput={{
              productVariantsFirst: VARIANT_FIRST,
              collectionsFirst: 10,
              metafieldProductKeys: getProductMetafieldProccessedKeys("DISPLAY_NAME", "EMBROIDERY_EMPLACEMENT_TYPE"),
            }}
          >
            <CustomerProvider
              customerInput={{
                includeMetafields: true,
                metafieldKeys: [CUSTOMER_METAFIELDS.GENDER, CUSTOMER_METAFIELDS.BIRTHDAY],
              }}
            >
              <AnalyticsProvider>
                <JotaiProvider store={globalStore}>
                  <LazyMotion strict features={motionAsyncFeatures}>
                    <AlertProvider>
                      {children}
                      <PanelManager />
                      <PopinManager />
                      <EventsBufferHandler />
                      <CustomAlerts />
                    </AlertProvider>
                  </LazyMotion>
                </JotaiProvider>
                <ShopifyAnalytics hasUserConsent />
              </AnalyticsProvider>
            </CustomerProvider>
          </CartProvider>
        </QueryClientProvider>
      </StoreProvider>
    </GoogleReCaptchaProvider>
  )
}
